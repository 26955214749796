import { template as template_80c0acbd79764932837fdb54640605ab } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_80c0acbd79764932837fdb54640605ab(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
