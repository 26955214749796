import { template as template_4ffbfd0e3136459992f78e782c55b27e } from "@ember/template-compiler";
const FKLabel = template_4ffbfd0e3136459992f78e782c55b27e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
