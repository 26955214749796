import { template as template_41423d36e3464392a3016abb2fa0c304 } from "@ember/template-compiler";
const FKControlMenuContainer = template_41423d36e3464392a3016abb2fa0c304(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
